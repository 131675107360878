(function ($) {
    let $fn = $(".comp_offer_detail");

    if ($fn.length) {
        backgroundImage($fn);

        $fn.on("click",".elm_link",function (e) {
            e.preventDefault();
            $fn.find(".wrp_comp_background").removeClass("mod--active");
            //AJAX fake load next pr prev offer
            //gallery
            
            var $el = $(this);
            $.ajax({
                url: $el.attr('href'),
                data: {ajax: 1, 'uri': $el.attr('href'), 'do': 'changeSpecialOffers'}
            }).done(function() {
                backgroundImage($fn);
            });
        });
    }
})(jQuery);