(function($) {
    var $fn = $("#layout_header"),
        $nav = $("#layout_nav");

    if ($fn.length) {
        $('.ssm-nav').slideAndSwipe();

        var headroom = new Headroom($fn[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    }

    if ($nav.length) {
        var $animation_delay = 60,
            $animation_duration = 600;

        $.fn.fn_animate = function($menu_id,$direction,$val) {
            var $el = $(this),
                $animation_delay = $el.parent().find("[data-menu=\""+$menu_id+"\"]").data("menu-delay") + $animation_duration;

            var $anim_out, $anim_in;
            if ($direction === "in") {
                $anim_out = "animate--out-left";
                $anim_in = "animate--in-right"
            } else {
                $anim_out = "animate--out-right";
                $anim_in = "animate--in-left"
            }

            $el.closest(".elm_content").addClass("mod--animated");

            $el.addClass($anim_out).parent().find("[data-menu=\""+$menu_id+"\"]").addClass($anim_in);


            if ($nav.find("[data-menu-breadcrumbs]").children("li").length === 1) {
                $nav.find("[data-submenu-back]").removeClass("mod--active");
            }

            setTimeout(function(){
                $el.removeClass($anim_out+" mod--active").parent().find("[data-menu=\""+$menu_id+"\"]").removeClass($anim_in).addClass("mod--active");
                $el.closest(".elm_content").removeClass("mod--animated");
                if ($direction === "in") {
                    $nav.find("[data-menu-breadcrumbs]").children("li").removeClass("mod--active").parent().append("<li class='mod--active' data-menu-id='"+$menu_id+"'>"+$val+"</li>").find("mod--active");
                }

                if ($nav.find("[data-menu-breadcrumbs]").children("li").length > 1) {
                    $nav.find("[data-submenu-back]").addClass("mod--active");
                }
            }, $animation_delay);
        };

        $nav.find("[data-menu]").each(function() {
            var $el = $(this),
                $el_length = $el.children("li").length - 1;

            $el.children("li").each(function(i){
                $(this).css("animation-delay", i*$animation_delay+"ms");
            });

            $el.attr("data-menu-delay", $el_length * $animation_delay);
        });


        $nav.on("click", "[data-menu-breadcrumbs] [data-menu-id]", function() {
            var $el = $(this),
                $menu_active = $nav.find("[data-menu].mod--active"),
                $menu_id = $el.data("menu-id");

            if (!$el.hasClass("mod--active")) {
                $el.addClass("mod--active").nextAll().remove();
                $menu_active.fn_animate($menu_id, "out");
            }
        });


        $nav.on("click", "[data-submenu]", function(e){
            e.preventDefault();
            var $el = $(this),
                $val = $el.prev().find("i").text(),
                $menu_id = $el.data("submenu");

            $el.closest("[data-menu]").fn_animate($menu_id, "in", $val);
        });

        $nav.on("click", "[data-submenu-back]", function(e){
            e.preventDefault();
            var $menu = $nav.find("[data-menu].mod--active"),
                $menu_prev = $("[data-menu-breadcrumbs] [data-menu-id].mod--active").prev(),
                $menu_id = $menu_prev.data("menu-id");

            $menu_prev.addClass("mod--active").nextAll().remove();
            $menu.fn_animate($menu_id, "out");
        });
    }
})(jQuery);