(function ($) {
    let $fn = $(".comp_slider_offers");

    if ($fn.length) {
        cssLoaded(function () {
            let $carousel = $fn.find("[data-comp-carousel]");

            $carousel.flickity({
                pageDots: false,
                wrapAround: true,
                contain: true,
                cellAlign: 'left'
            })
        })
    }
})(jQuery);