(function ($) {
    let $fn = $(".comp_slider_rooms");

    if ($fn.length) {
        cssLoaded(function () {
            let $carousel = $fn.find("[data-comp-carousel]");

            if($carousel.children().length > 3) {
                $carousel.flickity({
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    cellAlign: 'left'
                })
            }
        })
    }
})(jQuery);