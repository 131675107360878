(function ($) {
    let $fn = $(".comp_gallery");

    if ($fn.length) {
        let $carousel = $fn.find("[data-comp-carousel]"),
            $bg = $fn.find(".wrp_comp_background"),
            $albums = $fn.find(".wrp_comp_albums");

        $fn.find("[data-lightgallery-list]").lightGallery({
            thumbnail: true,
            selector: ".elm_gallery_item",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            download: true,
            autoplay: false,
            autoplayControls: false
        });

        if($carousel.children().length > 1) {
            $carousel.flickity({
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true,
                contain: true,
                cellAlign: 'left',
            });
            let $flkty = $carousel.data('flickity');
            backgroundImage($fn, true);

            $fn.on("click",".elm_link",function () {
                $bg.removeClass("mod--active");
                if($(this).hasClass("prev")){
                    $carousel.flickity('previous');
                }
                else {
                    $carousel.flickity('next');
                }
            });

            $carousel.on('select.flickity', function() {
                let $index = $($flkty.selectedElement).data("gallery");
                $albums.find(".mod--active").removeClass("mod--active");
                $albums.find("[data-gallery=\""+$index+"\"]").addClass("mod--active");
                setTimeout(function () {
                    backgroundImage($fn,true);
                },400);
            });
            
            $albums.on("click","[data-gallery]",function () {
                let $index = $carousel.find("[data-gallery=\""+$(this).data("gallery")+"\"]").first().index();
                $carousel.flickity('select',$index);
            })
        }
        else {
            backgroundImage($fn);
        }
    }
})(jQuery);