(function ($) {
    let $fn = $(".comp_featuring");

    if ($fn.length) {
        fn_scroll();

        $(window).scroll(function () {
            fn_scroll();
        });

        $(window).on('resize',function () {
            fn_scroll();
        });

        function fn_scroll(){
            if($(window).width() > 768){
                if($(document).scrollTop() + 200 > $fn.offset().top && $(document).scrollTop() + 200 < $fn.offset().top + $fn.outerHeight()) {
                    let $scroll = (($(document).scrollTop() + 200 - $fn.offset().top)/10);
                    $fn.find(".wrp_comp_body > .col:last-of-type").css({
                        'transform': "translate3D(0," + (-56+$scroll) + "px,0)"
                    });

                    $fn.find(".wrp_comp_body > .col:first-of-type").css({
                        'transform': "translate3D(0," + -($scroll/2) + "px,0)"
                    });
                }
            }
            else {
                $fn.find(".wrp_comp_body > .col").css({
                    'transform': ""
                });
            }

        }
    }
})(jQuery);