function isElementInViewport(el) {
    let rect = el.getBoundingClientRect();
    let fitsLeft = (rect.left >= 0 && rect.left <= $(window).width());
    let fitsTop = (rect.top >= 0 && rect.top <= $(window).height());
    let fitsRight = (rect.right >= 0 && rect.right <= $(window).width());
    let fitsBottom = (rect.bottom >= 0 && rect.bottom <= $(window).height());
    return {
        top: fitsTop,
        left: fitsLeft,
        right: fitsRight,
        bottom: fitsBottom,
        all: (fitsLeft && fitsTop && fitsRight && fitsBottom)
    };
}