(function ($) {
    let $fn = $(".comp_explore");

    if ($fn.length) {
        cssLoaded(function () {
            let $carousel = $fn.find("[data-comp-carousel]"),
                $carousel_autoplay = $carousel.data("comp-carousel");

            let $autoplay = $carousel_autoplay;
            if ($carousel_autoplay === "0" || $carousel_autoplay === "") {
                $autoplay = false;
            }
            if($carousel.children().length > 1){
                $carousel.flickity({
                    pageDots: true,
                    wrapAround: true,
                    prevNextButtons: false,
                    autoPlay: $autoplay
                })
            }
        })
    }
})(jQuery);