function backgroundImage($comp, isSlider = false) {
    let $img = (!isSlider) ? $comp.find(".wrp_comp_image img") : $comp.find(".is-selected img"),
        $bg = $comp.find(".wrp_comp_background");

    $img.beforeImgLoad(function() {
        cssLoaded(function(){
            $bg.css("background-image","url('"+$img.data("src")+"')");
            setTimeout(function () {
                $bg.addClass("mod--active");
            },100);
        });
    });
}