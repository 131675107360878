(function ($) {
    let $fn = $(".comp_room_detail");

    if ($fn.length) {
        $fn.find("[data-lightgallery-list]").lightGallery({
            thumbnail: true,
            selector: ".elm_gallery_item",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            download: true,
            autoplay: false,
            autoplayControls: false
        });

        let $lang = $("html").attr("lang"),
            $form = $fn.find(".wrp_comp_book form");
        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+$lang+".min.js").done(function () {
            let $dp_in_alt = $form.find("[data-alt=\"checkin\"]"),
                $dp_out_alt = $form.find("[data-alt=\"checkout\"]"),
                $today = new Date(),
                $prefill_dates = $form.data("prefill");

            let $dp_options = {
                language: $lang,
                dateFormat: "d, M. yyyy",
                altFieldDateFormat: "yyyy-mm-dd",
                position: "bottom left",
                autoClose: true,
                onHide: function(inst){
                    inst.update('position', 'bottom left'); // Update the position to the default again
                },
                onShow: function(inst, animationComplete){
                    // Just before showing the datepicker
                    if(!animationComplete){
                        var iFits = false;
                        // Loop through a few possible position and see which one fits
                        $.each(['bottom left', 'top left', 'top right', 'bottom right'], function (i, pos) {
                            if (!iFits) {
                                inst.update('position', pos);
                                var fits = isElementInViewport(inst.$datepicker[0]);
                                if (fits.all) {
                                    iFits = true;
                                }
                            }
                        });
                    }
                }
            };

            let $dp_in = $form.find("[data-datepicker=\"checkin\"]").datepicker($.extend({},$dp_options,{
                altField: $dp_in_alt,
                minDate: $today,
                onSelect:function ($fd,$d,$inst) {
                    $dp_out.update({
                        minDate: new Date($d.getTime() + (24*60*60*1000))
                    });
                    setTimeout(function () {
                        if($d > new Date($dp_out['$el'].siblings("input").val())){
                            $dp_out.selectDate(new Date($d.getTime() + (24*60*60*1000)))
                        }
                    },50);
                }
            })).data('datepicker');

            let $dp_out = $form.find("[data-datepicker=\"checkout\"]").datepicker($.extend({},$dp_options,{
                altField: $dp_out_alt,
                minDate: new Date($today.getTime() + (24*60*60*1000)),
                startDate: new Date($today.getTime() + (24*60*60*1000)),
                onSelect:function ($fd,$d,$inst) {

                }
            })).data('datepicker');

            /*Prefill dates*/

            if($prefill_dates){
                $dp_in.selectDate($today);
                $dp_out.selectDate(new Date($today.getTime() + (24*60*60*1000)))
            }

            /*Open DP on wrapper click*/
            $form.on("click",".wrp_datepicker",function () {
                $(this).find("[data-datepicker]").focus();
            })
        });
    }
})(jQuery);